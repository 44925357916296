// extracted by mini-css-extract-plugin
export var title = "d_c";
export var titleImg = "d_k";
export var titleWrapper = "d_l";
export var service = "d_m";
export var serviceWrapper = "d_n";
export var whiteBox = "d_p";
export var boxs = "d_q";
export var sns = "d_r";
export var explanation = "d_s";
export var icon = "d_t";
export var ads = "d_v";
export var snsContainer = "d_w";
export var snsBox = "d_x";
export var row1 = "d_y";
export var contact = "d_z";
export var form = "d_B";
export var flexbox = "d_C";
export var flexboxColumn = "d_D";
export var submit = "d_F";
export var privacyLink = "d_G";
export var confirmActions = "d_H";
export var confirmCancel = "d_J";