import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { DefaultLayout } from "../../organisms/DefaultLayout";
import * as styles from "../../styles/careers/pcad.module.scss";
import Lineicon from "../../images/ad/line-official-partner.png";
import Twittericon from "../../images/ad/twitter-official-partner.png";
import { Popup } from "../../atoms/popup/Popup";
import * as privacyStyles from "../../styles/careers/404.module.scss";
import { PrivacyPolicyContent } from "../../organisms/privacy/PrivacyPolicyContent";
import yahooImg from "../../images/ad/yahoo.png";
import googleImg from "../../images/ad/google.jpg";
import youtubeImg from "../../images/ad/youtube.png";
import lineImg from "../../images/ad/line.png";
import twitterImg from "../../images/ad/twitter.png";
import smartNewsImg from "../../images/ad/smartnews.jpg";
import gunosyImg from "../../images/ad/gunosy.png";
import laptopImg from "../../images/ad/ad_bg.png";
import { SEO } from "../../atoms/seo/SEO";
import seoImage from "../../images/vision/vision-osaka.jpg";
import axios from "axios";
import { navigate } from "gatsby";
import { SERV_HOST } from "../../atoms/constans";

const inquiryUrl = "/inquiry-ad.php";

axios.defaults.baseURL = SERV_HOST;
axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
// TODO: テスト用。いらなくなったら消す。
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

export default ({ location: pathname }) => {
  const [privacyPopupOpen, setPrivacyPopupOpen] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [body, setBody] = useState("");

  const onSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsConfirming(true);
    return false;
  };

  const submit = async () => {
    try {
      await axios.post(inquiryUrl, { name, email, tel: phone, text: body });
      await navigate("/thanks");
    } catch (err) {
      switch (err.response?.status) {
        case 422:
          // console.log(err.response.data);
          break;
        default:
          console.error(err);
          break;
      }
    }
  };

  return (
    <DefaultLayout className="">
      <SEO
        pathname={pathname}
        title="広告事業部 | AD"
        description="広告主と消費者をつなぐ架け橋に。サービスや商品を認知を広め費用対効果の高い広告運用を提供します。"
        image={seoImage}
      />
      <div className={styles.title}>
        <img className={styles.titleImg} src={laptopImg} alt="" />
        <div className={styles.titleWrapper}>
          <h2>AD</h2>
          <h3>広告事業部</h3>
          <h4>広告主と消費者をつなぐ架け橋に</h4>
          <p>
            サービスや商品を認知を広め
            <br />
            費用対効果の高い広告運用を提供します
          </p>
        </div>
      </div>

      <div className={styles.service}>
        <div className={styles.serviceWrapper}>
          <h2>SERVICES</h2>
          <h3>事業内容</h3>

          <div className={styles.boxs}>
            <div className={styles.whiteBox}>
              <h5>
                運用型広告
                <br />
                最適化
              </h5>
            </div>
            <div className={styles.whiteBox}>
              <h5 className={styles.sns}>SNS広告</h5>
            </div>
            <div className={styles.whiteBox}>
              <h5>
                インターネット
                <br />
                広告配信
              </h5>
            </div>
          </div>

          <div className={styles.explanation}>
            <p>
              近年スマートフォンの普及などにより、4マス広告とよばれる広告に代わって、WEB広告が大きく成長し続けています。SNSのタイムラインや動画の間で見かける広告など、もはや見たことがない人はいないと言っても過言ではありません。
              <br />
              広告事業部ではYahoo・GoogleをはじめとするSNS広告等、さまざまなweb広告に配信が可能です。それにより、ターゲットを絞って商品やサービスの集客をすることができるため、既存事業との連携による企業拡大、様々なクライアントの広告代理運用・最適化と幅広い価値を提供します。
            </p>

            <div className={styles.icon}>
              <div className="flex-1" />
              <img src={Lineicon} alt="" />
              <img src={Twittericon} alt="" />
              <div className="flex-1" />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.ads}>
        <h2>ADS</h2>
        <h3>取り扱い媒体</h3>

        <div className={styles.snsContainer}>
          <div className={styles.snsBox}>
            <div className="flex-1" />
            <img src={yahooImg} alt="" />
            <h4>yahoo！広告</h4>
            <div className="flex-1" />
          </div>
          <div className={styles.snsBox}>
            <div className="flex-1" />
            <img src={googleImg} alt="" />
            <h4>Google広告</h4>
            <div className="flex-1" />
          </div>
          <div className={styles.snsBox}>
            <div className="flex-1" />
            <img src={youtubeImg} alt="" />
            <h4>Youtube広告</h4>
            <div className="flex-1" />
          </div>
          <div className={styles.snsBox}>
            <div className="flex-1" />
            <img src={lineImg} alt="" />
            <h4>LINE広告</h4>
            <div className="flex-1" />
          </div>
        </div>

        <div className={styles.snsContainer}>
          <div className={styles.snsBox}>
            <div className="flex-1" />
            <img src={twitterImg} alt="" />
            <h4>Twitter広告</h4>
            <div className="flex-1" />
          </div>
          <div className={styles.snsBox}>
            <div className="flex-1" />
            <img src={smartNewsImg} alt="" />
            <h4>SmartNews Ads</h4>
            <div className="flex-1" />
          </div>
          <div className={styles.snsBox}>
            <div className="flex-1" />
            <img src={gunosyImg} alt="" />
            <h4>Gunosy Ads</h4>
            <div className="flex-1" />
          </div>
        </div>
      </div>

      <div className={styles.contact}>
        <h2>CONTACT</h2>
        <h3>お問い合わせ</h3>

        <div className={styles.form}>
          <form onSubmit={onSubmit}>
            <div className={styles.flexbox}>
              <p>お名前(必須)</p>
              <input
                value={name}
                onChange={e => setName(e.target.value)}
                required={true}
              />
            </div>

            <div className={styles.flexbox}>
              <p>メールアドレス（必須）</p>
              <input
                value={email}
                onChange={e => setEmail(e.target.value)}
                required={true}
              />
            </div>

            <div className={styles.flexbox}>
              <p>お電話番号(必須)</p>
              <input
                value={phone}
                onChange={e => setPhone(e.target.value)}
                required={true}
              />
            </div>

            <div className={styles.flexboxColumn}>
              <p>お問い合わせ内容(必須)</p>
              <textarea
                value={body}
                onChange={e => setBody(e.target.value)}
                required={true}
              />
            </div>

            <p>
              お問い合わせには、
              <span
                className={styles.privacyLink}
                onClick={() => setPrivacyPopupOpen(true)}
              >
                プライバシーポリシー
              </span>
              へ同意が必要です
            </p>
            <input className={styles.submit} type="submit" value="確認" />
          </form>
        </div>
      </div>

      <Popup
        open={isConfirming}
        onClose={() => setIsConfirming(false)}
        footer={
          <div className={styles.confirmActions}>
            <button onClick={onSubmit}>送信</button>
          </div>
        }
      >
        <div className={styles.confirmCancel}>
          <button onClick={e => setIsConfirming(false)}>×</button>
        </div>
        <>
          <div className={styles.flexbox}>
            <p>お名前(必須)</p>
            <span className="flex-1">{name}</span>
          </div>

          <div className={styles.flexbox}>
            <p>メールアドレス（必須）</p>
            {email}
          </div>

          <div className={styles.flexbox}>
            <p>ご連絡可能な電話番号</p>
            {phone}
          </div>

          <div className={styles.flexbox}>{body}</div>
        </>
      </Popup>

      <Popup open={privacyPopupOpen} onClose={() => setPrivacyPopupOpen(false)}>
        <div
          className={`${privacyStyles.privacyContent} ${privacyStyles.contactPopup}`}
        >
          <div className={styles.confirmCancel}>
            <button onClick={e => setIsConfirming(false)}>×</button>
          </div>
          <PrivacyPolicyContent />
        </div>
      </Popup>
    </DefaultLayout>
  );
};
